// /**
//  * forEach implementation for Objects/NodeLists/Arrays, automatic type loops and context options
//  *
//  * @private
//  * @author Todd Motto
//  * @link https://github.com/toddmotto/foreach
//  * @param {Array|Object|NodeList} collection - Collection of items to iterate, could be an Array, Object or NodeList
//  * @callback requestCallback      callback   - Callback function for each iteration.
//  * @param {Array|Object|NodeList} scope=null - Object/NodeList/Array that forEach is iterating over, to use as the this value when executing callback.
//  * @returns {}
//  */
var forEach = function (t, o, r) {
	if ("[object Object]" === Object.prototype.toString.call(t))
		for (var c in t)
			Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t);
	else for (var e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t);
};

function docReady(fn) {
	// see if DOM is already available
	if (
		document.readyState === "complete" ||
		document.readyState === "interactive"
	) {
		// call on next available tick
		setTimeout(fn, 1);
	} else {
		document.addEventListener("DOMContentLoaded", fn);
	}
}

function addClassToBody() {
	let body = document.body;

	if (
		!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		)
	) {
		body.classList.add("is_pc");
	} else {
		body.classList.add("is_mobile");
	}
}

function minHeight() {
	if (jQuery(".site-main").length) {
		var heighttarget = parseInt(jQuery(window).height(), 10);
		if (jQuery(".site-header").length) {
			heighttarget =
				heighttarget - parseInt(jQuery(".site-header").outerHeight(true), 10);
		}

		if (jQuery(".site-footer").length) {
			heighttarget =
				heighttarget - parseInt(jQuery(".site-footer").outerHeight(true), 10);
		}
		jQuery(".site-main").css("min-height", heighttarget);
	}
}

// function equal_height($elementSource, $elementCible) {
// 	if ($elementSource.length && $elementCible.length) {
// 		$elementCible.css('height', parseInt($elementSource.outerHeight(true), 10));
// 	}
// }

// function min_height($element) {
// 	if ($element.length) {
// 		var heighttarget = parseInt(windowheight, 10);
// 		if ($('.site-header').length) {
// 			heighttarget = heighttarget - parseInt($('.site-header').outerHeight(true), 10);
// 		}

// 		$element.css('min-height', heighttarget);
// 	}
// }
