function initResize() {
	minHeight();
}

function init() {
	initResize();

	//BEFORE
	addClassToBody();

	jQuery("#backtotop").click(function () {
		jQuery("body,html").animate(
			{
				scrollTop: 0,
			},
			800
		);
	});

	var offsetAncor = 50;
	if (location.hash) {
		setTimeout(function () {
			window.scrollTo(0, 0);
		}, 1);
		setTimeout(function () {
			id = window.location.hash;
			if (jQuery(id).length) {
				var offset = jQuery(id).offset().top - offsetAncor;
				jQuery("html, body").animate(
					{
						scrollTop: offset,
					},
					"slow"
				);
			}
		}, 1000);
	}

	jQuery("a[href*='#']:not([href='#'])").click(function (e) {
		if (
			location.hostname == this.hostname &&
			this.pathname.replace(/^\//, "") == location.pathname.replace(/^\//, "")
		) {
			e.preventDefault();

			var anchor = jQuery(this.hash);
			anchor = anchor.length
				? anchor
				: jQuery("[name=" + this.hash.slice(1) + "]");
			if (anchor.length) {
				jQuery("html, body").animate(
					{
						scrollTop: anchor.offset().top - offsetAncor,
					},
					1000,
					function () {
						if (window.innerWidth < 767) {
							if (jQuery(".site-navigation").hasClass("expanded")) {
								jQuery(".site-navigation").removeClass("expanded").slideUp(250);
								jQuery(".nav-trigger span").removeClass("open");
							}
						}
					}
				);
			}
		}
	});

	var hamburgers = document.querySelectorAll(".hamburger");
	if (hamburgers.length) {
		forEach(hamburgers, function (hamburger) {
			hamburger.addEventListener(
				"click",
				function () {
					this.classList.toggle("is-active");
					this.closest("header")
						.querySelector("nav")
						.classList.toggle("is-open");
					if (this.closest("header").querySelector("nav").style.maxHeight) {
						this.closest("header").querySelector("nav").style.maxHeight = null;
					} else {
						this.closest("header").querySelector("nav").style.maxHeight =
							this.closest("header").querySelector("nav").scrollHeight + "px";
					}
				},
				false
			);
		});
	}

	if (jQuery(".site-home-slider .slick").length) {
		jQuery(".site-home-slider .slick").slick({
			infinite: true,
			dots: true,
			fade: true,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
		});
	}

	if (jQuery(".wpb-slider .slick").length) {
		jQuery(".wpb-slider .slick").slick({
			infinite: true,
			dots: true,
			fade: true,
			speed: 700,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
		});
	}
	jQuery(".click-home-content-scrolldown").click(function (event) {
		event.preventDefault();
		document
			.getElementsByClassName("sticky-wrapper")[0]
			.scrollIntoView({ behavior: "smooth" });
	});
}

var popupsTrigger = document.querySelectorAll(".popup-trigger, .popup-close");
if (popupsTrigger.length) {
	popupsTrigger.forEach(function (popupTrigger) {
		popupTrigger.addEventListener(
			"click",
			function () {
				var popup = document.querySelector("." + popupTrigger.dataset.popup);
				popup.classList.toggle("open");
				if ("popup-infos" == popupTrigger.dataset.popup) {
					Cookies.set("popup-infos", "true", { expires: 1 });
				}
			},
			false
		);
	});
}

var cookie = Cookies.get("popup-infos");
var popupsNewsletter = document.querySelectorAll(".site-popup.popup-infos");

if (popupsNewsletter.length) {
	if (cookie === "true") {
		popupsNewsletter.forEach(function (popupsNews) {
			popupsNews.classList.remove("open");
		});
	} else {
		popupsNewsletter.forEach(function (popupsNews) {
			popupsNews.classList.add("open");
		});
	}
}
